// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-freelance-tsx": () => import("./../src/pages/freelance.tsx" /* webpackChunkName: "component---src-pages-freelance-tsx" */),
  "component---src-pages-honig-tsx": () => import("./../src/pages/honig.tsx" /* webpackChunkName: "component---src-pages-honig-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

